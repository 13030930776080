import React from 'react';
import isNil from 'utils/isNil';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const subheadOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <>{children}</>,
  },
};

const GatedHeroSection = ({
  subTitle,
  title,
  description,
  webinarContent,
  backgroundColor,
  ...otherProps
}) => {
  const backImage = otherProps.backgroundImage?.image;

  return (
    <SectionWebinarHero backgroundImage={backImage?.file?.url} backgroundColor={backgroundColor}>
      <div className="section-webinar-hero--container">
        <div className="section-webinar-hero--text-content">
          {!isNil(subTitle) && <h2 className="section-webinar-hero--subtitle">{subTitle}</h2>}
          <h1 className="section-webinar-hero--title font-h2">{title}</h1>
          {description?.raw && (
            <p className="section-webinar-hero--description">
              {renderRichText(description, subheadOptions)}
            </p>
          )}
        </div>

        {!isNil(webinarContent) && (
          <div className="section-webinar-hero--webinar-content">
            <div className="section-webinar-hero--webinar-wrapper">
              {webinarContent.map((webinarItem, index1) => {
                return (
                  <div className="section-webinar-hero--webinar-item" key={index1}>
                    <div className="section-webinar-hero--webinar-item-wrapper">
                      <h2 className="section-webinar-hero--webinar-title">{webinarItem.title}</h2>
                      <p className="section-webinar-hero--webinar-description">
                        {webinarItem.content}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </SectionWebinarHero>
  );
};

GatedHeroSection.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.object,
};

const SectionWebinarHero = styled.div`
  background-image: ${({ backgroundImage }) => backgroundImage && `url(${backgroundImage})`};
  background-color: ${(props) => props.backgroundColor && props.backgroundColor};
  background-size: cover;

  .section-webinar-hero--container {
    padding: 36px 412px 60px 28px;
    max-width: 1252px;
    margin: 0 auto;

    .section-webinar-hero--logo-wrapper {
      a {
        & > svg {
          width: 234px;
        }
      }
    }

    .section-webinar-hero--text-content {
      padding-right: 100px;
      margin-top: 100px;

      .section-webinar-hero--subtitle {
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-900);
        padding-bottom: 20px;
        line-height: var(--line-height-110);
        color: var(--grey-3);
      }

      .section-webinar-hero--title {
        color: var(--white);
      }

      .section-webinar-hero--description {
        font-size: var(--font-size-20);
        font-weight: var(--font-weight-500);
        line-height: var(--line-height-150);
        color: var(--white);
        margin-top: 20px;
      }
    }

    .section-webinar-hero--webinar-content {
      position: relative;

      .section-webinar-hero--webinar-wrapper {
        position: absolute;
        display: flex;
        background: var(--white);
        box-shadow: 0px 3px 10px rgba(11, 26, 33, 0.08);
        width: calc(100% - 32px);
        margin-top: 90px;
        padding: 24px 0;

        .section-webinar-hero--webinar-item {
          padding: 0 28px;
          width: 100%;
          border-right: 1px solid var(--grey-4);

          .section-webinar-hero--webinar-item-wrapper {
            max-width: 186px;
            margin: 0 auto;

            .section-webinar-hero--webinar-title {
              font-weight: var(--font-weight-500);
              font-size: var(--font-size-16);
              line-height: var(--line-height-150);
              color: var(--dark);
            }

            .section-webinar-hero--webinar-description {
              font-weight: bold;
              font-size: var(--font-size-20);
              line-height: var(--line-height-120);
              letter-spacing: var(--letter-spacing--0-01);
              color: black;
              margin: 0;
              padding: 0;
              margin-top: 6px;
            }
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) {
    .section-webinar-hero--container {
      padding: 25px 412px 100px 28px;
      .section-webinar-hero--logo-wrapper {
        a {
          & > svg {
            width: 160px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .section-webinar-hero--container {
      padding-bottom: 50px;
      margin-bottom: 0;

      .section-webinar-hero--logo-wrapper {
        a {
          & > svg {
            width: 161px;
          }
        }
      }

      .section-webinar-hero--text-content {
        padding-right: 0;
        margin-top: 110px;

        .section-webinar-hero--description {
          font-size: var(--font-size-16);
        }
      }

      .section-webinar-hero--webinar-content {
        .section-webinar-hero--webinar-wrapper {
          position: relative;
          margin-top: 45px;

          .section-webinar-hero--webinar-item {
            .section-webinar-hero--webinar-item-wrapper {
              .section-webinar-hero--webinar-title {
                font-size: var(--font-size-13);
              }

              .section-webinar-hero--webinar-description {
                font-size: var(--font-size-16);
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-webinar-hero--container {
      .section-webinar-hero--text-content {
        margin-top: 60px;
      }

      .section-webinar-hero--webinar-content {
        .section-webinar-hero--webinar-wrapper {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 695px) {
    .section-webinar-hero--container {
      padding: 60px 20px 28px;

      .section-webinar-hero--rate-content {
        margin-top: 15px;
      }
    }
  }
`;

export default GatedHeroSection;
